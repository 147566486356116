import { useState } from "react"
import { AreaHierarchyType } from "../../../interfaces/system/areaTypes"
import { CapacityGridFieldTypes, CapacityGridReportingFieldTypes, CapacityGridSummaryReportFilterOptions, CapacityGridSummaryReportResultType } from "../../../interfaces/system/capacityGridType"
import BasicReportFilter, { BasicReportCriteria, BasicReportFilterCriteria } from "../basic-report-filter"
import { Grid } from "@mui/material"
import { ExcelExportSheet, exportToExcel } from "../../../services/reporting/excel-export-service"
import React from "react"
import moment from "moment"
import CapacityGridResultRangeDisplay from "../../core/capacityGridResultRangeDisplay"

interface CapacityGridSummaryReportProps {
    areaHierarchy: AreaHierarchyType
    onFetchData: (options: CapacityGridSummaryReportFilterOptions) => void
    data?: CapacityGridSummaryReportResultType
    criteria?: BasicReportFilterCriteria
}

export function CapacityGridSummaryReport(props: CapacityGridSummaryReportProps) {
    const [useSummaryFields, setUseSummaryFields] = useState(true);

    const handleApplyFilter = (criteria: BasicReportCriteria) => {
        props.onFetchData({
            serviceIds: criteria.selectedServiceIds.map(m => m.id),
            useSummaryFields: criteria.capGridSummaryReportCheck ?? false,
            month: criteria.selectedMonthId,
            year: criteria.selectedYear,
            bedTypeId: (criteria.capGridSummaryReportCheck ?? false) ? undefined : criteria.selectedBedTypeId
        });
        setUseSummaryFields(criteria.capGridSummaryReportCheck ?? false);
    }

    const handleExport = () => {
        const title = 'Capacity Grid Summary Report';

        const reportTables = document.querySelectorAll("table");
        if ((reportTables?.length ?? 0) > 0) {
            let sheets: ExcelExportSheet[] = [];
            reportTables.forEach((table, index) => {
                let sheetTitle = table.dataset.capGridName ?? `Division ${index} data`;
                let sheetName = table.dataset.capGridName ?? `Division ${index} data`;
                // excel sheet names are limited to 31 chars
                const limit = 31;
                // if greater than 31
                if ((sheetName.length ?? 0) > limit) {
                    // truncate
                    sheetName = `${sheetName.substr(0, (limit - 5))}...`;
                    // if this results in a duplicate add an index at the end
                    if (sheets.find(s => s.name === sheetName)) {
                        sheetName += ` ${index}`
                    }
                }

                sheets.push({
                    title: sheetTitle,
                    name: sheetName,
                    elements: [
                        { table: table },
                    ]
                });
            });
            exportToExcel({
                fileName: title,
                defaultHeaderWidth: 20,
                sheets: sheets
            });
        }
    }

    return <Grid container spacing={2} >
        <Grid item xs={10}>
            <BasicReportFilter areaHierarchy={props.areaHierarchy} onApplyFilter={handleApplyFilter} criteria={props.criteria} singleServiceSelection={false}
                defaultFilterCriteria={{ capGridSummaryReportCheck: true, selectedServiceIds: [] }}
                validateServiceSelections={true}
                onExport={handleExport} />
        </Grid>
        <Grid item xs={11}>
            {props.data && (props?.data?.grids?.length ?? 0) > 0 &&
                <>
                    {React.Children.toArray(props.data?.grids.map((grid) => {
                        const gridDefinition = props.data?.gridDefinitions.find(d => d.id === grid.capacityGrid.id);
                        const gridColumns = useSummaryFields ? gridDefinition?.columns?.filter(g => g.reportingFieldType.id === CapacityGridReportingFieldTypes.Included) : gridDefinition?.columns;
                        const gridColCount = gridColumns?.length ?? 0;
                        if (!gridDefinition) {
                            return <></>
                        }
                        if (!gridColumns) {
                            return <><p>Capacity grid does not have any fields to display</p></>
                        }
                        let daysInMonth: number[] = [];
                        for (var i = 1; i <= (props.data?.daysInMonth ?? 1); i++) {
                            daysInMonth.push(i);
                        }
                        return <>
                            <h2>{grid.capacityGrid.name}</h2>
                            <div style={{ width: '100%', overflow: 'auto' }}>
                                <table className={"capacity-grid-daily-report condensed bordered table-highlight cap-grid-report"} data-cap-grid-name={grid.capacityGrid.name}>
                                    <thead>
                                        <tr>
                                            <td>&nbsp;</td>
                                            {React.Children.toArray(daysInMonth.map((day) => {
                                                return <><td style={{ textAlign: 'center' }} data-remove-colspan-on-export colSpan={gridColCount}>{day}</td></>
                                            }))}
                                        </tr>
                                        <tr>
                                            <th>&nbsp;</th>
                                            {React.Children.toArray(daysInMonth.map((day) => {
                                                return <>
                                                    {React.Children.toArray(gridColumns.map((col) => {
                                                        return <th>{col.name}</th>
                                                    }))}
                                                </>
                                            }))}
                                        </tr>
                                    </thead>
                                    <tbody>


                                        {React.Children.toArray(grid.services.map((service) => {
                                            return <>
                                                <tr>
                                                    <th className='text-left' colSpan={(daysInMonth.length * gridColCount) + 1}>
                                                        {service.parentService.name}
                                                    </th>
                                                </tr>
                                                {React.Children.toArray(service.divisions.map((division) => {
                                                    return <>
                                                        <tr>
                                                            <td>
                                                                {division.service.name}
                                                            </td>
                                                            {React.Children.toArray(daysInMonth.map((day) => {
                                                                const date = moment(`${props.data?.year}-${props.data?.month}-${day}`);
                                                                const dataForDay = division.submissionData?.find(d => d && d.createdOn && moment(d.createdOn).format('DD-MM-YYYY') === date.format('DD-MM-YYYY'));

                                                                return <>
                                                                    {React.Children.toArray(gridColumns.map((col) => {
                                                                        const colData = dataForDay?.columns?.find(c => c.capacityGridColumnId === col.id);
                                                                        if (!colData) {
                                                                            return <td>&nbsp;</td>
                                                                        }

                                                                        return <CapacityGridResultRangeDisplay displayAsTableCell={true} rowConfig={col.numericFieldOptions} centerIfNumeric={true} cellValue={colData.value ?? ''}></CapacityGridResultRangeDisplay>
                                                                    }))}
                                                                </>
                                                            }))}
                                                        </tr>
                                                    </>
                                                }))}
                                                <tr>
                                                    <td className='th text-left'>
                                                        
                                                    </td>

                                                    {React.Children.toArray(daysInMonth.map((day) => {
                                                                
                                                            const totals:number[] =[]
                                                            const date = moment(`${props.data?.year}-${props.data?.month}-${day}`);
                                                            gridColumns.map((col,colIndex) => {
                                                                service.divisions.forEach((division) => {

                                                                    const dataForDay = division.submissionData.find(d => d && d.createdOn && moment(d.createdOn).format('DD-MM-YYYY') === date.format('DD-MM-YYYY'));
                                                                    const colData = dataForDay?.columns?.find(c => c.capacityGridColumnId === col.id);
                                                                            
                                                                    if (!colData) {
                                                                        return
                                                                    }
                                                                    if(!totals[colIndex])totals[colIndex] = 0
                                                                    totals[colIndex] = totals[colIndex] + parseInt(colData.value==='' ?'0': colData.value ?? '0');
                                                                }
                                                            
                                                            )})
                                                                

                                                        return <>
                                                            {React.Children.toArray(gridColumns.map((col,colIndex) => {
                                                                let total = 0;
                                                                let result = ""
                                                                if (col.fieldType.id !== CapacityGridFieldTypes.Numeric &&
                                                                    col.fieldType.id !== CapacityGridFieldTypes.Calculated) {
                                                                    return <td className="th text-center">&nbsp;</td>
                                                                }
                                                                return <>
                                                                    {service.divisions.forEach((division) => {
                                                                        const date = moment(`${props.data?.year}-${props.data?.month}-${day}`);
                                                                        const dataForDay = division.submissionData.find(d => d && d.createdOn && moment(d.createdOn).format('DD-MM-YYYY') === date.format('DD-MM-YYYY'));
                                                                        //const colData = dataForDay?.columns?.find(c => c.capacityGridColumnId === col.id);
                                                                        // if (!colData) {
                                                                        //     return
                                                                        // }
                                                                        //total = total + parseInt(colData.value==='' ?'0': colData.value ?? '0');

                                                                        total = totals[colIndex]
                                                                        if(!total) total = 0

                                                                        if(col.fieldType.id=== CapacityGridFieldTypes.Calculated){
                                                                            let formula = col?.calculatedFieldFormula??'';

                                                                            let regexExtractFields = /\{(.*?)\}/g;
                                                                            let formulaFieldRefs = Array.from(formula.matchAll(regexExtractFields), (m) => m[1]);
                                                                            if (formulaFieldRefs) {
                                                                                
                                                                                formulaFieldRefs.forEach(f => {
                                                                                    let refCell = gridColumns.find(c => { return c.name === f });
                                                                                    const colIndex = dataForDay?.columns.findIndex(c => c.capacityGridColumnId === refCell?.id);
                                                                                    let val = totals[colIndex??0]
                                                                                    if(!val) val = 0
                                                                                        formula = formula?.replace(`{${f}}`, val?.toString())
                                                                                    
                                                                                });
                                                                                // eslint-disable-next-line no-eval
                                                                                
                                                                                result = eval(formula)
                                                                                if(result.toString().indexOf('NaN')>-1 || 
                                                                                result.toString().indexOf('Infinity')>-1)
                                                                                    result = "0"                                                                                
                                                                        }}
                                                                    })}
                                                                    <CapacityGridResultRangeDisplay displayAsTableCell={true} className={`text-center bold`} rowConfig={  col.numericFieldOptions} cellValue={col.fieldType.id=== CapacityGridFieldTypes.Calculated?result:total.toString()}></CapacityGridResultRangeDisplay>
                                                                </>
                                                            }))}
                                                        </>
                                                    }))}

                                                </tr>
                                            </>
                                        }))}
                                    </tbody>
                                </table>
                            </div>
                        </>
                    }))}
                </>
            }

            {props.data && (props.data.grids?.length ?? 0) <= 0 &&
                <p>No results for selected criteria.</p>
            }
        </Grid>
    </Grid>
}