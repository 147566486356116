import React from "react";
import { useEffect, useState } from "react";
import { CapacityGridFieldTypes, CapacityGridSubmissionColumnType, CapacityGridSubmissionDataType, CapacityGridSubmissionOverviewType, CapacityGridType } from "../../interfaces/system/capacityGridType"
import { ServiceType } from "../../interfaces/system/serviceType"
import { createNewUser } from "../../interfaces/user/iUser";
import { saveCapacityGridSubmission } from "../../services/capacity-grid-service";
import ActionButton from "../core/actionButton";
import Loading from "../core/loading";
import CapacityGridSubmissionEditorRow from "./capacity-grid-subission-editor-row";
import { ComponentPropsBase } from "../../interfaces/system/componentPropsBase";

interface ICapacityGridSubmissionEditorTableProps extends ComponentPropsBase {
    ParentServiceName: string,
    Divisions: CapacityGridSubmissionOverviewType[],
    CapacityGrid: CapacityGridType,
    onSaved: (rowData: CapacityGridSubmissionDataType, capacityGrid: CapacityGridType, division: ServiceType) => void,
    onRowError?: (errorMessage: string, rowData?: CapacityGridSubmissionDataType) => void,
    onMessageChange: (message: string) => void
}

interface ICapacityGridRowTotal {
    capacityGridColumnId: number,
    name: string,
    value?: number,
    cellType: CapacityGridFieldTypes
}

export default function CapacityGridSubmissionEditorTable(props: ICapacityGridSubmissionEditorTableProps) {
    // component vars
    const verticalHeaderThreshold: number = 1;
    const headerBaseHeight: number = 130;

    // state vars
    const [divisionList, setDivisionList] = useState<CapacityGridSubmissionOverviewType[]>(props.Divisions);
    const [capacityGrid, setCapacityGrid] = useState<CapacityGridType>(props.CapacityGrid);
    const [rowItems, setRowItems] = useState<CapacityGridSubmissionDataType[]>();
    const [headerHeight, setHeaderHeight] = useState<number>(headerBaseHeight);
    const [cellTotals, setCellTotals] = useState<ICapacityGridRowTotal[]>();
    const [editRowMode, setEditRowMode] = useState(false);
    const [rowsToEdit, setRowsToEdit] = useState<CapacityGridSubmissionDataType[]>([]);
    const [editingRowIndex, setEditingRowIndex] = useState(-1);

    // hooks
    useEffect(() => {
        generateRowData();
    }, [])

    useEffect(() => {
        calcHeaderHeight();
        if (rowItems && rowItems.length > 1) {
            calculateColumnTotals();
        }
    }, [rowItems])

    // component methods

    const getColumnValueFromPreviousSubmission = (colId: number, divisionId: number): [string?, number?] => {
        //return ['', 0];
        const divisionData = divisionList?.find(d => d.service.id === divisionId);
        const columnToEdit = divisionData?.submissionData?.columns?.find(x => x.capacityGridColumnId === colId);
        if (!columnToEdit) {
            return ['', 0];
        }
        return [columnToEdit?.value ?? undefined, columnToEdit?.columnOptionId ?? undefined];
    }

    const generateRowData = async () => {
        handleLoading();
        const columns: CapacityGridSubmissionColumnType[] = capacityGrid.columns.sort((a, b) => a.sortOrder - b.sortOrder).map((col) => {
            return { id: 0, capacityGridSubmissionId: 0, capacityGridColumnId: col.id, columnOptionId: 0, value: '', fieldType: col.fieldType, currentValue: '', isKeyField: col.isKeyField };
        });

        const rows: CapacityGridSubmissionDataType[] = divisionList.filter(div => div.service.capacityGridId === capacityGrid.id).sort((a, b) => a.service.sortOrder - b.service.sortOrder).map((division) => {

            const colsWithValues: CapacityGridSubmissionColumnType[] = columns.map((col) => {
                const [textVal, dropDownVal] = getColumnValueFromPreviousSubmission(col.capacityGridColumnId, division.service.id);
                if (dropDownVal) {
                    return { ...col, value: '', columnOptionId: dropDownVal }
                }
                return { ...col, value: textVal, columnOptionId: 0 }
            });
            const submissionData = divisionList?.find(d => d.service.id === division.service.id)?.submissionData;
            let createdOnDate = submissionData?.createdOn;
            let createdBy = submissionData?.createdBy ?? createNewUser();

            let keyFields = capacityGrid.columns.filter(c => c.isKeyField);
            let keyFieldLabel = '';
            if ((keyFields?.length ?? 0) !== 1) {
                handleRowError(`Capacity grid '${capacityGrid.name}' has multiple or no key field(s) set, please rectify before saving data`);
            }
            keyFieldLabel = keyFields[0]?.name;
            return { id: 0, capacityGridId: capacityGrid.id, serviceId: division.service.id, keyFieldLabel: keyFieldLabel, keyFieldValue: '', createdBy: createdBy, createdOn: createdOnDate, columns: colsWithValues };
        })
        setRowItems(rows);
        handleLoaded();
    }

    const calcHeaderHeight = (): void => {
        if ((capacityGrid?.columns?.length ?? 0) <= 0) {
            return;
        }

        const maxLength = capacityGrid.columns.reduce((prev, current) => {
            return prev.name.length > current.name.length ? prev : current
        }).name.length;
        setHeaderHeight(headerBaseHeight * Math.ceil(maxLength / 18));
    }
    const getHeaderWidth = (val: string): number => {
        return (val?.length ?? 0) <= 10 ? 80 : Math.ceil(val.length * 6);
    }

    const calculateColumnTotals = () => {
        
        let totals = capacityGrid.columns.map((col) => {
            let total: number | undefined = 0;
            let cellType: CapacityGridFieldTypes = col.fieldType.id;

            rowItems?.forEach(division => {
                let cellData = division.columns.find(c => c.capacityGridColumnId === col.id)!;
                if (cellData?.value && (cellType === CapacityGridFieldTypes.Numeric || cellType === CapacityGridFieldTypes.Calculated)) {
                    total = (total ?? 0) + parseInt(cellData.value ?? '0');
                    if(isNaN(total)) total = 0
                }
            })
            return { name: col.name, value: Number.isInteger(total) ? total : undefined, cellType: cellType, capacityGridColumnId: col.id };
        })
        if (totals) {
            setCellTotals([...updateCalculatedTotals(totals)]);
        }
    }

    const updateCalculatedTotals = (currentData: ICapacityGridRowTotal[]): ICapacityGridRowTotal[] => {
        
        // get all calc fields
        let calculatedCellsEvaluated = currentData.map(cell => {
            if (cell.cellType !== CapacityGridFieldTypes.Calculated) {
                return cell;
            }
            // get cap. grid col data
            let columnData = capacityGrid.columns.find(col => { return col.id === cell.capacityGridColumnId });
            let newValue = 0;

            // get all values for referenced fields
            let formula = columnData?.calculatedFieldFormula ?? '';

            // get tokens
            let regexExtractFields = /\{(.*?)\}/g;
            let formulaFieldRefs = Array.from(formula.matchAll(regexExtractFields), (m) => m[1]);
            if (formulaFieldRefs) {

                // get token values
                formulaFieldRefs.forEach(token => {
                    const val = currentData.find(d => d.name === token)?.value ?? 0;
                    // replace tokens
                    formula = formula?.replace(`{${token}}`, val + '')
                })
                // eslint-disable-next-line no-eval
                newValue = eval(formula);

            }
            if(isNaN(newValue)) newValue = 0
            return { ...cell, value: newValue }

        });
        return [...calculatedCellsEvaluated];
    }

    // event handlers
    const setToolTipText = (event: React.MouseEvent<HTMLDivElement>, text: string) => {
        const box: HTMLDivElement = event.currentTarget;
        const table = box.closest("table");
        if (table) {
            const tooltipRow = table.querySelector(".tooltip-text");
            if (tooltipRow) {
                tooltipRow.textContent = text;
            }
        }
    }

    const clearToolTipText = (event: React.MouseEvent<HTMLDivElement>) => {
        const box: HTMLDivElement = event.currentTarget;
        const table = box.closest("table");
        if (table) {
            const tooltipRow = table.querySelector("td.tooltip-text");
            if (tooltipRow) {
                tooltipRow.innerHTML = '&nbsp;';
            }
        }
    }

    const handleRowSaved = (rowData: CapacityGridSubmissionDataType, division: ServiceType) => {
        props.onSaved(rowData, capacityGrid, division);
        if (rowItems && rowItems.length > 1) {
            calculateColumnTotals();
        }
    }

    const handleRowUpdated = (rowData: CapacityGridSubmissionDataType) => {
        if (editRowMode) {
            let itemFound = false;
            let editedRows = rowsToEdit.map(i => {
                if (i.capacityGridId === rowData.capacityGridId && i.serviceId === rowData.serviceId) {
                    itemFound = true;
                    return rowData;
                }
                return i;
            })
            if (!itemFound) {
                editedRows.push(rowData);
            }
            setRowsToEdit(editedRows);
        }
    }

    const handleMessageChange = (message: string) => {
        props.onMessageChange(message);
    }

    const handleRowError = (errorMessage: string, rowData?: CapacityGridSubmissionDataType) => {
        if (props.onRowError) {
            props.onRowError(errorMessage, rowData);
        }
        handleLoaded();
    }

    const handleError = (message: string) => {
        if (props.onError) {
            props.onError(message);
        }
    }

    const handleLoading = () => {
        if (props.onLoading) {
            props.onLoading();
        }
    }
    const handleLoaded = () => {
        if (props.onLoaded) {
            props.onLoaded();
        }
    }

    const handleEnableRowEdit = () => {
        setRowsToEdit([]);
        setEditRowMode(true);
    }

    const handleCancelRowEdit = () => {
        setRowsToEdit([]);
        setEditRowMode(false);
    }

    const handleSaveRowClick = async () => {
        let savedItems: string[] = [];
        if ((rowsToEdit?.length ?? 0) > 0) {
            rowsToEdit.forEach(async (rowItem, index) => {
                const division = divisionList.find(x => x.service.id === rowItem.serviceId);
                savedItems.push(`${division?.service.name}` ?? '');
                setEditingRowIndex(index);
                const response = await saveCapacityGridSubmission(rowItem);
                setEditingRowIndex(-1);
                if (!response || !response.success || !response.result) {
                    handleRowError(response.error ?? "Unknown error", rowItem);
                }
            });
            handleMessageChange(`The following items have been saved: ${savedItems.join(", ")} for ${capacityGrid.name}`);
        }
        handleCancelRowEdit();
        setTimeout(() => { }, 500);
    }

    // render
    if (capacityGrid && (capacityGrid?.columns?.length ?? 0) <= 0) {
        return <>Error loading capacity grid, please check capacity grid configuration</>
    }
    if (!rowItems || (capacityGrid?.columns?.length ?? 0) <= 0) {
        return <>Loading data...</>
    }
    return <>
        <div className="capacity-grid-entry-responsive-container">
            <table className={"capacity-grid-entry"} onMouseOut={(e) => { clearToolTipText(e) }} >
                <thead>
                    <tr>
                        <th style={{position:'sticky',left:0}} className="service-name">&nbsp;</th>
                        {React.Children.toArray(capacityGrid.columns.sort((a, b) => a.sortOrder - b.sortOrder).map((col) => {
                            return <th className={(capacityGrid.columns.length > verticalHeaderThreshold ? "rotate" : "")}>
                                <div style={{ minWidth: (capacityGrid.columns.length > verticalHeaderThreshold ? getHeaderWidth(col.name) : undefined), height: (capacityGrid.columns.length > verticalHeaderThreshold ? headerHeight : undefined) }} onMouseOver={(e) => { setToolTipText(e, col.name) }} className={"rotated-th"}>
                                    <span className={"rotated-th__label"}>{col.name}</span>
                                </div>
                            </th>
                        }))}
                        <th className={(capacityGrid.columns.length > verticalHeaderThreshold ? "rotate" : "")}>
                            <div style={{ height: (capacityGrid.columns.length > verticalHeaderThreshold ? headerHeight : undefined), minWidth: '150px' }} onMouseOver={(e) => { setToolTipText(e, "Date Added") }} className={"rotated-th"}>
                                <span className={"rotated-th__label"}>Last Updated</span>
                            </div>
                        </th>
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="tooltip-text-row">
                        {capacityGrid.columns.length > verticalHeaderThreshold && <td style={{ textAlign: 'left', paddingLeft: '5px', minWidth: '200px' }} colSpan={0}>{props.ParentServiceName ?? ' '}</td>}
                        <td className="tooltip-text" colSpan={capacityGrid.columns.length > verticalHeaderThreshold ? (capacityGrid.columns.length) : 200}>&nbsp;</td>
                        {capacityGrid.columns.length > verticalHeaderThreshold && <td colSpan={2}>&nbsp;</td>}
                    </tr>
                    {React.Children.toArray(rowItems?.map((row, index) => {
                        let division: ServiceType = divisionList?.find(d => d.service.id === row.serviceId)?.service!;

                        return <>
                            <CapacityGridSubmissionEditorRow EnableEditRowMode={editRowMode} RowShowLoading={editingRowIndex === index} key={`editor_row_${row.capacityGridId}_${index}`} RowItem={row} CapacityGrid={capacityGrid} Division={division} OnUpdated={handleRowUpdated} OnSave={handleRowSaved} OnError={handleRowError}></CapacityGridSubmissionEditorRow>
                        </>
                    }))}
                    {cellTotals && cellTotals.length > 1 && <>
                        <tr className="total-row">
                            <td className="align-left" style={{position:'sticky',left:0}}><div>Total</div></td>
                            {React.Children.toArray(cellTotals?.map((cellTotal, index) => {

                                let toolTipText = cellTotal.cellType === CapacityGridFieldTypes.Text ? '\u00A0' : `Total: ${cellTotal.name} ${cellTotal.value}`;
                                return <td onMouseOver={(e) => { setToolTipText(e, toolTipText) }}>
                                    {(cellTotal.cellType === CapacityGridFieldTypes.Numeric || cellTotal.cellType === CapacityGridFieldTypes.Calculated) && cellTotal.value}
                                </td>
                            }))}
                            <td> </td>
                            <td className="edit-button">
                                {!editRowMode && <ActionButton size="small" label="Edit" severity="success" onClick={handleEnableRowEdit}></ActionButton>}
                                {editRowMode && <><ActionButton size="small" label="Save" severity="success" onClick={handleSaveRowClick}></ActionButton>&nbsp;<ActionButton size="small" label="Cancel" severity="info" onClick={handleCancelRowEdit}></ActionButton></>}
                            </td>

                        </tr>
                    </>}
                </tbody>
            </table>
        </div>
    </>
}