import ServiceValidator from "../../validators/serviceValidator"
import { DomainEntityType } from "../domain/iDomainEntity"
import { UserType } from "../user/iUser"
import { ServiceDistributionListType } from "./distributionListTypes"
import { AddressType,createNewAddress } from "./iAddress"


export enum ServiceCategoryEnum {
   Acute = 10,
   Combined = 50,
   IntegratedCareSystem = 51,
   Opel=30
}
export enum DistributionListNotificationEnum {
    ComplianceLevel1 = 1,
    ComplianceLevel2 = 2,
    ComplianceLevel3 = 3,
    CapacityGrid = 4
 }

export const isCombinedService = (categoryId: ServiceCategoryEnum) => {
   
   if(!categoryId) return false
   
   return categoryId.toString()=== ServiceCategoryEnum.Combined.toString() || 
   categoryId.toString()=== ServiceCategoryEnum.IntegratedCareSystem.toString() || 
   categoryId.toString()=== ServiceCategoryEnum.Opel.toString()
}
export interface ServiceTypeLite  {
   id:number
   parentId?:number
    parentServiceId?:number
   hasPermission:boolean
   formId?:number
   name:string
   capacityGridId?:number
   categoryId:number
}
 
export interface ServiceType extends ServiceTypeLite {
    id:number
    name:string
    address:AddressType
    sortOrder:number
    code:string
    trustId?:number
    areaId:number
    formResultId?:number
    opelFormResultId?:number
    validator?:ServiceValidator
    featureIds:number[]
    deleted:boolean
    formResultWeightings:ServiceGroupingResultOptionRangeType[]
    serviceGrouping:ServiceGroupingType[]
    opelMappings?:OpelMappingType[]
    cutOffAM?:string
    cutOffPM?:string
    costPerWeek:number
    genderId?:number
    bedTypeId?: number
    bedType?: DomainEntityType
    fundingTypeId?:number
    opelAutomation?:boolean
    opelSubmitToShrewd?:boolean
    divisions?:ServiceType[],
    distributionLists?: ServiceDistributionListType[]
    opelDistributionListId?: number
 }


 
 export interface ServiceOverviewType  {
   form:DomainEntityType
   service:ServiceStatusType
}

 export interface ServiceStatusContainerType  {
    services:ServiceStatusType[]
 }
 export interface ServiceStatusType  {
    id:number
    category:DomainEntityType
    area:DomainEntityType
    name:string
    address:AddressType
    code:string
    trustId?:number
    areaId: number
    parentServiceId?:number
    formId?:number
    formResultId?:number
    capacityGridId?:number
    validator?:ServiceValidator
    featureIds:number[]
    comments?:string
    contactDetails?:string
    contactName?:string
    demandAndCapacityProviderCode?:string
    formResultShortName?:string
    opelFormResultShortName?:string
    opelAutomation?:boolean
    lastUpdatedOn?:Date
    lastUpdatedBy?:UserType
    formResultOptionId?:number
    opelFormResultOptionId?:number
    score?:number
    opelScore?:number
    previousScore?:number
    costPerWeek?:number
    fundingTypeId?: number
    bedTypeId?:number
    genderId?: number
    capacityGridOverview?: CapacityGridOverviewType
    hasDivisionsWithCapacityGridResults:boolean
}

export interface ServiceSearchResultType   {
   id:number
   category:DomainEntityType
   name:string
   address:AddressType
}
export interface CapacityGridOverviewType   {
    capacityGridSubmissionId?:number
    capacityGridSubmittedByUserId?:number
    capacityGridSubmittedOn?:Date
    capacityGridKeyFieldLabel?:string
    capacityGridKeyFieldValue?: string
    capacityGridSubmittedByName?:string
}
export interface ServiceGroupingType {
   serviceId:number
   weightings:ServiceGroupingWeightingType[]
   opelWeighting?:number
 }
 export interface OpelMappingType {
   serviceId:number
   parameterId:number
   externalParameterId:number
   deleted?:boolean
 }
 export interface ServiceGroupingWeightingType {
   formResultOptionId:number
   weighting:number
 }
 export interface ServiceGroupingResultOptionRangeType {
   formResultOptionId:number
   weightingFrom:number
   weightingTo:number
 }

export const createNewService = ():ServiceType => {
   return {id:-Date.now(),categoryId:0,name:'',
      address:createNewAddress(),
      sortOrder:0,
      code:'',
      areaId:0, costPerWeek:0,
      validator:new ServiceValidator(),
      featureIds:[],
      deleted:false, fundingTypeId:undefined,
      formResultWeightings:[], hasPermission:false,
      serviceGrouping:[]}
}
export const createNewServiceLite = ():ServiceTypeLite => {
   return {id:-Date.now(),name:'',hasPermission:false,categoryId:0}
}
export const createNewServiceLiteFromParams = (id:number,name:string):ServiceTypeLite => {
   return {id:id,name:name,hasPermission:false,categoryId:0}
}

export const serviceCanBeSubmitted = (service:ServiceOverviewType) =>{
   
   return service?.form?.id && (service?.service?.category.id??-1).toString() !== ServiceCategoryEnum.Opel.valueOf().toString()
}
export interface ServiceFeatureType{
   name:string
   sortOrder:number
   id:number
}
export const createNewServiceFeatureType = ():ServiceFeatureType => {
   return {id:-Date.now(),name:'', sortOrder:0}
}
export interface ServiceTrustType{
   name:string
   sortOrder:number
   id:number
}
export const createNewServiceTrustType = ():ServiceTrustType => {
   return {id:-Date.now(),name:'', sortOrder:0}
}
export interface BedType{
   name:string
   necsName?:string
   sortOrder:number
   id:number
}
export const createNewBedType = ():BedType => {
   return {id:-Date.now(),name:'', sortOrder:0}
}

export interface FundingType{
   name:string
   necsName?:string
   sortOrder:number
   id:number
}
export const createNewFundingType = ():FundingType => {
   return {id:-Date.now(),name:'', sortOrder:0}
}
