import React,{useState,useEffect} from "react";
import {Grid} from '@mui/material'
import {FormFooterSubmissionFieldType, FormSubmissionType, FormFooterFieldTypeEnum} from "../../interfaces/system/formType"
import TextBox from "../core/textBox";
interface FormSubmissionFooterProps {
    form?:FormSubmissionType
    onUpdate:(fields: FormFooterSubmissionFieldType[], valid:boolean) => void
    submitted:number
    id?:number
  }

  interface FormSubmissionFooterFieldType {
    field?:FormFooterSubmissionFieldType
    isIncomplete?:boolean
  }
  interface FormSubmissionFooterStandardFieldsType {
    comments:FormSubmissionFooterFieldType
    contactName:FormSubmissionFooterFieldType
    contactNumber:FormSubmissionFooterFieldType
  }

  
const FormSubmissionFooter:  React.FC<FormSubmissionFooterProps> = (props) => {
  //const [form, setForm] = useState<FormSubmissionType>();
  const [basicFooter, setBasicFooter] = useState<FormSubmissionFooterStandardFieldsType>();
  const [submitted, setSubmitted] = useState(props.submitted)
  const [isBasic, setIsBasic] = useState(props?.id===20)

  useEffect(() => {
    const footer = {
      comments:{field:props.form?.footerFields?.find(f=> f.fieldType?.id == FormFooterFieldTypeEnum.Comments),isIncomplete:false },
      contactName:{field:props.form?.footerFields?.find(f=> f.fieldType?.id == FormFooterFieldTypeEnum.ContactName),isIncomplete:false },
      contactNumber:{field:props.form?.footerFields?.find(f=> f.fieldType?.id == FormFooterFieldTypeEnum.ContactNumber),isIncomplete:false }
    }
    
    setBasicFooter(footer)
    if(props.submitted>0)
    validate()
  }
  ,[props.form])
  
  useEffect(() => {
    setIsBasic(props?.id===20)
  }
  ,[props.id])


  useEffect(() => {
    setSubmitted(props.submitted)
    validate()
  }
  ,[props.submitted])

  const validate = ()=>{
    if(!basicFooter) return true

    let isValid = false
    if(!isBasic){
        basicFooter.contactName.isIncomplete = basicFooter?.contactName.field?.value == undefined || basicFooter?.contactName.field?.value===''
        basicFooter.contactNumber.isIncomplete = basicFooter?.contactNumber.field?.value == undefined || basicFooter?.contactNumber.field?.value===''
        basicFooter.comments.isIncomplete = false

        isValid = (basicFooter.contactName?.field?.isValid &&
            basicFooter.contactNumber?.field?.isValid)  ??false
    }else{
      basicFooter.comments.isIncomplete = basicFooter?.comments.field?.value == undefined || basicFooter?.comments.field?.value===''
      basicFooter.contactName.isIncomplete = false
        basicFooter.contactNumber.isIncomplete = false

        isValid = !basicFooter.comments.isIncomplete
    }

   
    const footerFields = [basicFooter.comments]

    if(!isBasic){
      footerFields.push(basicFooter.contactName)
      footerFields.push(basicFooter.contactNumber)
    }


    const footerSubmissionFields: FormFooterSubmissionFieldType[]=[]

    footerFields.forEach(f=> {
      footerSubmissionFields.push({fieldId : f.field?.fieldId ||0, value : f.field?.value, fieldTypeId:f.field?.fieldType?.id})
    })

      setBasicFooter({...basicFooter})
      props.onUpdate(footerSubmissionFields,
        isValid)
  }

  const handleFieldUpdated = (field?:FormSubmissionFooterFieldType, value?:string) => {
    if(!field?.field) return
    
    const b = basicFooter
    field.field.value = value;
    field.isIncomplete = value===undefined || value===''
    field.field.isValid =!field.isIncomplete;

    if(b)
      setBasicFooter({...b})

      validate()
  }
    return <>
    <Grid container spacing={1} >
      <Grid item xs={6}>
        <Grid container spacing={1} >
          <Grid item xs={12}>
              <TextBox error={submitted>0&&basicFooter?.comments?.isIncomplete} value={basicFooter?.comments?.field?.value} label="Comments" rows={5} onChange={(e)=> {handleFieldUpdated(basicFooter?.comments, e.target.value)}}/>
          </Grid>
          
        </Grid>
      </Grid>
      <Grid item xs={6}>
      <Grid container spacing={1} >
        {!isBasic && <Grid item xs={11}>
              <TextBox value={basicFooter?.contactName?.field?.value} label="On Call Manager" error={(submitted>0 && basicFooter?.contactName?.isIncomplete)} onChange={(e)=> {handleFieldUpdated(basicFooter?.contactName, e.target.value)}}/>
          </Grid>
        }
          
          {!isBasic && <Grid item xs={11}>
            <TextBox value={basicFooter?.contactNumber?.field?.value} label="Contact Number" error={submitted>0&&basicFooter?.contactNumber?.isIncomplete} onChange={(e)=> {handleFieldUpdated(basicFooter?.contactNumber, e.target.value)}}/>
          </Grid>}
               
              </Grid>
          </Grid>
      </Grid>
    </>;
}


export default FormSubmissionFooter;

