import React, {useEffect,useState} from "react"
import { createNewService, DistributionListNotificationEnum, isCombinedService, OpelMappingType, ServiceCategoryEnum, ServiceType } from "../../../interfaces/system/serviceType"
import useForm from "../../../hooks/useForm";
import {Grid} from '@mui/material';
import TextBox from "../../core/textBox";
import Dropdown from "../../core/dropdown";
import OKCancelButtons from "../../core/OKCancelButtons";
import Loader from "../../core/loading";
import BasicTab from "../../core/basicTab";
import AddressEditDetails from "../../address/addressDetails";
import SelectList from "../../core/selectList";
import { AddressType } from "../../../interfaces/system/iAddress";
import TickBox from "../../core/tickBox";
import ServiceValidator from "../../../validators/serviceValidator";
import ServiceGroupingEdit from "./serviceGroupingEdit";
import ServiceDivisionEdit from "./serviceDivisionEdit";
import ServiceDivisionList from "./serviceDivisionList";
import DebugVariable from "../../core/debugVariable";
import { ServiceDistributionListType } from "../../../interfaces/system/distributionListTypes";
import ServiceShrewdMappingsEdit from "./service-opel-mappings-edit";

interface ServiceEditProps {
    service?:ServiceType
    onSave: (service: ServiceType,valid:boolean) => void 
    onCancel: () => void 
  }

const ServiceEdit:  React.FC<ServiceEditProps> = (props) => {
  const [isService, setIsService] = useState(!isCombinedService(props.service?.categoryId??0))
  const [selectedDivision, setSelectedDivision]  = useState<ServiceType>()
  const [validator] = useState(props.service?.validator)
  const {
    validateForm, objectValues, setObjectValues,setValues
  }
   = useForm({initialObjectValues: props.service, validator: validator})
   const [selectedTabIndex, setSelectedTabIndex] = useState(validator?.tabIndex)
   const [selectedTabTitle, setSelectedTabTitle] = useState<string>()

   useEffect(() => {
        window.addEventListener('popstate', function() {
            props.onCancel()
        }, false);
   },[])
   

useEffect(() => {
  if(props.service?.id !== objectValues?.id){
    if(props?.service?.validator){
      props.service.validator= new ServiceValidator()
    }
    setObjectValues(props.service)
  }

  setIsService(!isCombinedService(props.service?.categoryId??0))
},[props.service])
useEffect(() => {
  if(selectedTabIndex)
    tabIndexToTitle(selectedTabIndex)
},[selectedTabIndex])
   
const handleSave = async () =>{
  let isValid = validateForm(objectValues)
  let tabToShow =validator?.tabIndex??0

  if(objectValues){
    const divisions:ServiceType[] = objectValues.divisions
    
    if(divisions){
      const showDivisionTab = isValid
      divisions.forEach(v => {
        if(v.validator)
          isValid = isValid && v.validator.validate(v)
      });
      if(showDivisionTab && !isValid && validator?.addressIsValid) tabToShow=2
      if(validator && validator?.addressIsValid)
        validator.tabIndex = 2
    }
  }
  setObjectValues({
    ...objectValues,
    validator: validator
 })
  props.onSave(objectValues, isValid)
  
  
  if(!isValid){
    setSelectedTabIndex(tabToShow)
}
    
  }
  const tabIndexToTitle =(tabIndex:number) =>{
    let tabTitle = ''
    switch(tabIndex){
      case 0: tabTitle = 'Service Details'; break;
      case 1: tabTitle = 'Contact Details'; break;
      case 2: tabTitle = 'Divisions'; break;
    }
    setSelectedTabTitle(tabTitle)
  }

  const handleCancel =() =>{if(props.onCancel) props.onCancel()}
  const updateAddressDetails = (addressDetails: AddressType) =>{
    setObjectValues({
      ...objectValues,
      address: addressDetails
   })
   
  }

  const updateDivisions = (service: ServiceType) =>{
    const parentService: ServiceType = objectValues

    const existingDivisionIndex =!parentService?.divisions ? -1:  parentService.divisions?.findIndex(f=> f.id === service.id)
    if(!parentService?.divisions) parentService.divisions = []

    if(existingDivisionIndex>=0){
      parentService.divisions[existingDivisionIndex]  = service
    }
    else{
      parentService.divisions.push( service)
    }


    setObjectValues({
      ...objectValues,
      divisions: parentService.divisions
   })
   
  }

  const handleFeaturesSelected = (selected:number[]) =>{
    setObjectValues({
      ...objectValues,
      featureIds: selected
   })
  }
  const handleCategoryUpdated = (e:any) =>{
    const catId = e.target.value as ServiceCategoryEnum
    setObjectValues({
      ...objectValues,
      categoryId: catId
   })
   setIsService(!isCombinedService(catId))
  }

  const handleGroupingUpdate = (service: ServiceType) => {
    setObjectValues({...objectValues, formResultWeightings: service.formResultWeightings, serviceGrouping:service.serviceGrouping,
      formResultId:service.formResultId, cutOffAM:service.cutOffAM, cutOffPM:service.cutOffPM, opelFormResultId:service.opelFormResultId,
      opelDistributionListId:service.opelDistributionListId
     })
  }
  const handleDivisionSelected = (division:ServiceType) => {
    setSelectedDivision(division)
  }
  const handleNewDivision = () => {
    let divisions = objectValues.divisions
    if(!divisions)
      divisions = []
    
    const newDivision = createNewService()
    newDivision.name = "new"
    newDivision.formId = objectValues.formId
    newDivision.capacityGridId = objectValues.capacityGridId
    newDivision.trustId = objectValues.trustId
    newDivision.areaId = objectValues.areaId
    newDivision.categoryId = objectValues.categoryId
    newDivision.address.id = newDivision.id
    
    divisions.push(newDivision)

    objectValues.divisions = divisions

    setObjectValues({...objectValues,divisions:divisions})
    setSelectedDivision(newDivision)
    updateDivisions(newDivision)
    }

    const getCapacityGridDistributionList = ():number => {
        if (!objectValues || (objectValues.distributionLists?.length ?? 0) <=0) {
            return 0;
        }
        const distLists = objectValues.distributionLists as ServiceDistributionListType[];
        return distLists.find(x => x.notificationTypeId === DistributionListNotificationEnum.CapacityGrid)?.distributionListId ?? 0;
    }
    const getComplianceDistributionList = (level: DistributionListNotificationEnum): number => {
        if (!objectValues || (objectValues.distributionLists?.length ?? 0) <=0) {
            return 0;
        }
        const distLists = objectValues.distributionLists as ServiceDistributionListType[];
        return distLists.find(x => x.notificationTypeId === level)?.distributionListId ?? 0;
    }

    const handleCapacityGridDistributionListChanged = (e:any) => {
        if (!objectValues) {
            return;
        }
        if ((objectValues.distributionLists?.length ?? 0) <= 0) {
            objectValues.distributionLists = [];
        }
        const distLists = objectValues.distributionLists as ServiceDistributionListType[];
        let newDistLists = [];
        distLists.forEach(d => {
            if (d.notificationTypeId !== DistributionListNotificationEnum.CapacityGrid) {
                newDistLists.push(d);
            }
        });
        newDistLists.push({ distributionListId: e.target.value, notificationTypeId: DistributionListNotificationEnum.CapacityGrid, serviceId: objectValues.id, members: [] });
        setObjectValues({ ...objectValues, distributionLists: newDistLists });
    }

    const handleComplianceDistributionListChanged = (e: any, level: DistributionListNotificationEnum) => {
        if (!objectValues) {
            return;
        }
        if ((objectValues.distributionLists?.length ?? 0) <= 0) {
            objectValues.distributionLists = [];
        }
        const distLists = objectValues.distributionLists as ServiceDistributionListType[];
        let newDistLists = [];
        distLists.forEach(d => {
            if (d.notificationTypeId !== level) {
                newDistLists.push(d);
            }
        });
        newDistLists.push({ distributionListId: e.target.value, notificationTypeId: level, serviceId:objectValues.id, members:[] });
        setObjectValues({ ...objectValues, distributionLists: newDistLists });
    }

    const handleShrewdMappingsUpdated = (opelMappings:OpelMappingType[])=>{
        setObjectValues({...objectValues, opelMappings:opelMappings})
    }

if(!objectValues)
        return <Loader loading={true} />
  
        const serviceDetail =  <Grid container spacing={2} justifyContent = "center" style={{padding:'15px 5px'}}>
                <Grid item xs={1}><TextBox id="id"  label="Id" value={objectValues.id} disabled={true} hidden={objectValues.id<1}/></Grid>
                
                <Grid item xs={2}><TextBox id="code" disabled={false}  label="Code"  maxLength={16}  value={objectValues.code} onChange={(e) => {setValues(e)}} /></Grid>
                {/* <Grid item xs={2}><TextBox id="sortOrder" type="number" label="Display Order" value={objectValues.sortOrder} onChange={(e) => {setValues(e)}} /></Grid> */}
                <Grid item xs={2}><TickBox id="opelAutomation"  label="Opel Automation" checked={objectValues.opelAutomation} onChange={(e) => {setValues(e)}}/></Grid>
                <Grid item xs={2}><TickBox id="opelSubmitToShrewd"  label="Submit to Shrewd" checked={objectValues.opelSubmitToShrewd} onChange={(e) => {setValues(e)}}/></Grid>
                <Grid item xs={1}><TickBox id="deleted"  label="Deleted" checked={objectValues.deleted} onChange={(e) => {setValues(e)}}/></Grid>
                <Grid item xs={8}><TextBox id="name" focus={true} label="Name" validationField={validator?.name} value={objectValues.name} onChange={(e) => {setValues(e)}} /></Grid>
                
                <Grid item xs={8} ><Dropdown id="areaId" label="Area" optionName="areas" includeBlankOption={true} value={objectValues.areaId} validationField={validator?.area}   onChange={(e) => {setValues(e)}} /></Grid>
                <Grid item xs={8} ><Dropdown id="categoryId" label="Service Type" optionName="serviceCategories"  includeBlankOption={true} validationField={validator?.category}  value={objectValues.categoryId}   onSelection={handleCategoryUpdated}  /></Grid>
                {isService && <>
                  <Grid item xs={8} ><Dropdown id="trustId" label="Trust" optionName="trusts" includeBlankOption={true}  value={objectValues.trustId}   onChange={(e) => {setValues(e)}} /></Grid>
                  <Grid item xs={8} ><Dropdown id="formId" label="Form" optionName="forms" includeBlankOption={true}  value={objectValues.formId}   onChange={(e) => {setValues(e)}} /></Grid>
                  <Grid item xs={8} ><Dropdown id="capacityGridId" label="Grid" optionName="capacity-grid" includeBlankOption={true}  value={objectValues.capacityGridId}   onChange={(e) => {setValues(e)}} /></Grid>
                </>}
    </Grid>;

    const addressDetail = <Grid container spacing={2} justifyContent = "center" style={{padding:'15px 5px'}}>
      <Grid item xs={8}>
            <AddressEditDetails formValidator={objectValues.validator.addressValidator} address={objectValues.address} onChange={updateAddressDetails}/>
            </Grid>
      </Grid>

    const divisionElem = objectValues?.id<=0 ? <p>Divisions can only be added once the service has been saved.</p> :
     <Grid container spacing={2} justifyContent = "center" style={{padding:'15px 5px'}}>
          <Grid item xs={4}>
          <ServiceDivisionList service={objectValues} onSelect={handleDivisionSelected} onNew={handleNewDivision}/>
        </Grid>
        <Grid item xs={8}>
          <ServiceDivisionEdit parentService={objectValues} service={selectedDivision} onChange={updateDivisions}/>
        </Grid>
    </Grid>

    const serviceFeatures = <Grid container spacing={0} justifyContent = "center" style={{padding:'15px 5px'}}>
                              <SelectList optionName="service-features-list" onChange={handleFeaturesSelected} selectedIds={objectValues.featureIds}/>
                            </Grid>

  
    const groupedServices =<Grid container spacing={0} justifyContent = "center" style={{padding:'15px 5px'}}>
                              <ServiceGroupingEdit service={objectValues} onUpdate={handleGroupingUpdate} />
    </Grid>

    const complianceNotifications = <Grid container justifyContent="center" rowGap={2} style={{ padding: '15px 5px' }}>
        <DebugVariable variable={objectValues.distributionLists} title="Selected item" hide={true} />
        <Grid item xs={6}>
            <Dropdown id="capacityGridDistributionList" label="Capacity Grid Notification Distribution List" optionName="distribution-list" includeBlankOption={true} value={getCapacityGridDistributionList()} onChange={handleCapacityGridDistributionListChanged} />
        </Grid>
        <Grid item xs={12} />

        <Grid item xs={6}>
            <Dropdown id="complianceDistributionListLevel1" label="Compliance Level 1 Distribution List" optionName="distribution-list" includeBlankOption={true} value={getComplianceDistributionList(DistributionListNotificationEnum.ComplianceLevel1)} onChange={(e) => handleComplianceDistributionListChanged(e, DistributionListNotificationEnum.ComplianceLevel1)} />
        </Grid>
        <Grid item xs={12} />
        
        <Grid item xs={6}>
            <Dropdown id="complianceDistributionListLevel2" label="Compliance Level 2 Distribution List" optionName="distribution-list" includeBlankOption={true} value={getComplianceDistributionList(DistributionListNotificationEnum.ComplianceLevel2)} onChange={(e) => handleComplianceDistributionListChanged(e, DistributionListNotificationEnum.ComplianceLevel2)} />
        </Grid>
        <Grid item xs={12} />
        
        <Grid item xs={6}>
            <Dropdown id="complianceDistributionListLevel3" label="Compliance Level 3 Distribution List" optionName="distribution-list" includeBlankOption={true} value={getComplianceDistributionList(DistributionListNotificationEnum.ComplianceLevel3)} onChange={(e) => handleComplianceDistributionListChanged(e, DistributionListNotificationEnum.ComplianceLevel3)} />
        </Grid>
        <Grid item xs={12} />

        <Grid item xs={12} />
        <Grid item xs={6}><TextBox id="capacityGridValidationPeriod" label="Grid validation period (days)" type="number" value={objectValues.capacityGridValidationPeriod ?? 0} onChange={(e) => { setValues(e) }} /></Grid>
    </Grid>


const shrewdMappings = <Grid container justifyContent="center" rowGap={2} style={{ padding: '15px 5px' }}>
        <Grid item xs={6}>
          <ServiceShrewdMappingsEdit service={objectValues}  onChange={handleShrewdMappingsUpdated} />
        </Grid>
    </Grid>


  return <>
    <Grid container justifyContent="center">
        <BasicTab key='serv_' selectedTabTitle={selectedTabTitle} tabs={[{label:'Service Details', component: <>{serviceDetail}</> },
                      {label:'Contact Details', component: <>{addressDetail}</>},
                      {label:'Divisions', hidden:(!isService || objectValues.id<1),component: <>{divisionElem}</>},
                      {label:'Grouped Services', hidden:isService, component: <>{groupedServices}</>},
                      {label:'Features', component: <>{serviceFeatures}</>},
                      { label: 'Compliance Notifications', component: <>{complianceNotifications}</>},
                      { label: 'Shrewd Mappings', hidden:!objectValues.opelSubmitToShrewd, component: <>{shrewdMappings}</>}]}
                      >
        </BasicTab>
          <Grid container spacing={1} justifyContent = "center" style={{padding:'15px 5px'}}>
            <Grid item xs={8}>
              <OKCancelButtons onOK={handleSave} onCancel={handleCancel} />
            </Grid>     
          </Grid>     
                  
    </Grid>
  </>

}

export default ServiceEdit;