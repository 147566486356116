import { Grid } from "@mui/material";
import React from "react";
import { useEffect, useState } from "react";
import { formatDateTime } from "../../../extensions/formatter";
import { AreaHierarchyType } from "../../../interfaces/system/areaTypes";
import { CapacityGridFieldTypes, CapacityGridHistoricReportFilterOptions, CapacityGridHistoricReportResultType, CapacityGridSubmissionDataType, CapacityGridType } from "../../../interfaces/system/capacityGridType";
import { fetchCapacityGridSubmissionList } from "../../../services/capacity-grid-service";
import { fetchHistoricReport } from "../../../services/reporting/capacity-grid-service";
import { cleanExcelSheetName, ExcelExportSheet, exportToExcel } from "../../../services/reporting/excel-export-service";
import { fetchAllAreaHierarchyWithServices } from "../../../services/systemService";
import { addDaysToTodaysDate } from "../../../services/utils";
import ActionButton from "../../core/actionButton";
import CapacityGridResultRangeDisplay from "../../core/capacityGridResultRangeDisplay";
import { PagedTab, PagedTabs } from "../../core/pagedTabs";
import BasicReportFilter, { BasicReportCriteria } from "../basic-report-filter";

export interface CapacityGridHistoricReportProps {
    parentServiceId?: number,
    onLoading?: () => void,
    onLoaded?: () => void,
    onError?: (message: string) => void,
    onBackButton?: () => void
    areaHierarchy: AreaHierarchyType
}

export function CapacityGridHistoricReport(props: CapacityGridHistoricReportProps) {
    const [areaHierarchy, setAreaHierarchy] = useState(props.areaHierarchy);
    const [hideServiceSelector] = useState(props.parentServiceId ? true : false);
    const [filterOptions, setFilterOptions] = useState<CapacityGridHistoricReportFilterOptions>({ parentServiceId: props.parentServiceId ?? 0, dateFrom: addDaysToTodaysDate(-7), dateTo: addDaysToTodaysDate(0) });
    const [reportData, setReportData] = useState<CapacityGridHistoricReportResultType[]>();
    const [capacityGrids, setCapacityGrids] = useState<CapacityGridType[]>();
    const [noticeText, setNoticeText] = useState<string>();

    useEffect(() => {
        loadCapacityGrids();
    }, [reportData]);

    useEffect(() => {
        if(!hideServiceSelector)
            setAreaHierarchy(props.areaHierarchy)
    }, [props.areaHierarchy]);

    useEffect(() => {
        loadData();
    }, [filterOptions]);

     

    const loadAreaHierarchy = async () => {
        handleLoading();
        const a = await fetchAllAreaHierarchyWithServices()
        if (a.success && a.result) {
            setAreaHierarchy(a.result);
        }
        handleLoaded();
    }

    const loadData = async () => {
        handleLoading();
        if (filterOptions.parentServiceId) {
            const response = await fetchHistoricReport(filterOptions);
            handleLoaded();
            if (response && response.success && response.result) {
                setReportData(response.result);

                return
            }
            handleError(response.error ?? 'Unkown error');
        }
        handleLoaded();
    }

    const loadCapacityGrids = async () => {
        handleLoading();
        // load all capacity grids this user has access to, this gives us the required structure/columns the grid will need to present/submit
        const response = await fetchCapacityGridSubmissionList();
        handleLoaded();
        if (response && response.success && response.result) {
            const grids = filterCapacityGrids(response.result);
            setCapacityGrids(grids);
            return
        }
    }

    // extract only the capacity grids that have a service from the returned list of services
    const filterCapacityGrids = (grids: CapacityGridType[]): CapacityGridType[] => {
        if (reportData && grids) {
            let capacityGridIds: number[] = [];
            reportData.forEach(service => {
                const gridId = service.service.capacityGridId ?? 0;
                if (gridId > 0 && !capacityGridIds.includes(gridId)) {
                    capacityGridIds.push(gridId);
                }
            });
            let newList: CapacityGridType[] = [];
            grids.forEach(grid => {
                if (capacityGridIds.includes(grid.id)) {
                    newList.push(grid);
                }
            });
            return newList;
        }
        return [];
    }

    const getColumnValue = (columnId: number, data?: CapacityGridSubmissionDataType, grid?: CapacityGridType): string => {
        if (data && grid) {
            const colData = data.columns.find(d => d.capacityGridColumnId === columnId);
            if (colData && colData.fieldType.id === CapacityGridFieldTypes.Text && (colData?.columnOptionId ?? 0) > 0) {
                const gridColumn = grid.columns.find(col => col.id === colData?.capacityGridColumnId);
                if (gridColumn) {
                    for (var i = 0; i < gridColumn.textFieldOptions.length; i++) {
                        const dropdownOption = gridColumn.textFieldOptions[i];
                        if (dropdownOption.id + '' === colData!.columnOptionId + '') {
                            return dropdownOption.value;
                        }
                    }
                }
            }
            return colData?.value ?? '';
        }
        return '';
    }



    //#region events

    const handleFilterSelectionsChanged = (selections: BasicReportCriteria) => {
        let serviceIds: number[] = [filterOptions.parentServiceId];
        if (!hideServiceSelector) {
            serviceIds = [];
            if (selections && selections.selectedServiceIds && (selections.selectedServiceIds?.length ?? 0) > 0) {
                serviceIds = selections.selectedServiceIds.map(svc => {
                    return svc.id;
                });
            }
            if ((serviceIds?.length ?? 0 > 1)) {
                setNoticeText("You have selected multiple services, only the first item selected will be displayed.");
            }
        }

        setFilterOptions({
            parentServiceId: serviceIds[0],
            dateFrom: selections.fromDate ?? addDaysToTodaysDate(-7),
            dateTo: selections.toDate ?? addDaysToTodaysDate(0)
        });
    }

    const handleExport = () => {
        const title = 'Capacity Grid Report';
        const reportTables = document.querySelectorAll("table");
        if ((reportTables?.length ?? 0) > 0) {
            let sheets: ExcelExportSheet[] = [];
            reportTables.forEach((table, index) => {
                let sheetTitle = table.dataset.exportSheetTitle ?? `Division ${index} data`;
                let sheetName = table.dataset.exportSheetName ?? `Division ${index} data`;
                // excel sheet names are limited to 31 chars
                const limit = 31;
                // if greater than 31
                if ((sheetName.length ?? 0) > limit) {
                    // truncate
                    sheetName = `${sheetName.substr(0, (limit - 5))}...`;
                    // if this results ina duplicate add an index at the end
                    if (sheets.find(s => s.name === sheetName)) {
                        sheetName += ` ${index}`
                    }
                }

                sheets.push({
                    title: sheetTitle,
                    name: sheetName,
                    elements: [
                        { table: table },
                    ]
                });
            });
            exportToExcel({
                fileName: title,
                defaultHeaderWidth: 20,
                sheets: sheets
            });
        }
    }

    const handleLoading = () => {
        if (props.onLoading) {
            props.onLoading();
        }
    }
    const handleLoaded = () => {
        if (props.onLoaded) {
            props.onLoaded();
        }
    }
    const handleBackButton = () => {
        if (props.onBackButton) {
            props.onBackButton();
        }
    }
    const handleError = (message: string) => {
        if (props.onError) {
            props.onError(message);
        }
    }

    //#endregion events


    //#region render functions
    const generateTable = (grid: CapacityGridType, division: CapacityGridHistoricReportResultType) => {
        const exportSheetName = cleanExcelSheetName(division.service.name.replace(`${division.parentService.name} - `, '').replace(division.parentService.name, '')).trim();
        return <table className={"capacity-grid-daily-report condensed table-highlight bordered cap-grid-report"} data-export-sheet-name={`${exportSheetName}`} data-export-sheet-title={`${division.parentService.name}`}>
            <thead>
                <tr>
                    <th>&nbsp;</th>
                    {React.Children.toArray(grid.columns.sort((a, b) => a.sortOrder - b.sortOrder).map(col => {
                        return <th>{col.name}</th>
                    }))}
                </tr>
            </thead>
            <tbody>
                <tr className="parent-name no-highlight">
                    <td colSpan={200}>{division.service.name}</td>
                </tr>
                {(!division.submissionData || division.submissionData.length <= 0) &&
                    <tr><td colSpan={200} className="text-center"><em>No data</em></td></tr>
                }
                {division && React.Children.toArray(division.submissionData.map(data => {

                    return <tr>
                        <td>{formatDateTime(data.createdOn)}</td>
                        {React.Children.toArray(grid.columns.sort((a, b) => a.sortOrder - b.sortOrder).map(col => {
                            const columnValue = getColumnValue(col.id, data, grid);
                            const isNumeric = parseInt(columnValue) >= 0 || col.fieldType.id !== CapacityGridFieldTypes.Text;
                            return <>
                                <CapacityGridResultRangeDisplay displayAsTableCell={true} className={`${isNumeric ? 'text-center' : ''}`} rowConfig={col.numericFieldOptions} cellValue={columnValue}></CapacityGridResultRangeDisplay>
                            </>
                        }))}
                    </tr>
                }))}
            </tbody>
        </table>
    }
    const generateTabs = (): PagedTab[] => {
        let tabs: PagedTab[] = [];
        if (capacityGrids && reportData) {

            capacityGrids.forEach(grid => {
                const divisionsForGrid = reportData.filter(d => d.service.capacityGridId === grid.id);
                if (!divisionsForGrid) {
                    return;
                }
                const parentName = divisionsForGrid[0].parentService.name;
                const tabContent = <div>

                    <h3 className="tab-content-title"><strong>{parentName}</strong></h3>
                    {React.Children.toArray(divisionsForGrid.map((division, index) => {
                        return generateTable(grid, division);
                    }))}
                </div>
                let tab: PagedTab = { title: grid.name, children: tabContent };
                tabs.push(tab);
            })
        }
        return tabs;
    }

    //#endregion

    return <>
        <Grid container>
            <Grid item xs={12}>
                <BasicReportFilter areaHierarchy={areaHierarchy} onApplyFilter={handleFilterSelectionsChanged} onExport={handleExport} hideServiceControl={hideServiceSelector} validateServiceSelections={false} criteria={{ showOnlyServicesWithGridAssociated: false, showServiceCategory: false, showBedAvailabilityList: false, showBedTypeList: false, showFromDate: true, showToDate: true }} defaultFilterCriteria={{ selectedServiceIds: [{ id: filterOptions.parentServiceId }], fromDate: filterOptions.dateFrom, toDate: filterOptions.dateTo }} />
            </Grid>
        </Grid>
        {props.onBackButton &&
            <div style={{ margin: '1rem 0', textAlign: 'right' }}>
                <ActionButton onClick={handleBackButton} label="Back" severity="success" icon="pi pi-chevron-left" />
            </div>
        }
        {!props.onBackButton &&
            <p>&nbsp;</p>
        }
        {noticeText && <>
            <p className="p-inline-message p-inline-message-warn" style={{marginBottom:'1rem'}}>{noticeText}</p>
        </>
        }


        {(reportData?.length ?? 0) > 0 &&
            <PagedTabs tabs={generateTabs()}></PagedTabs>
        }
    </>
}